import React, { useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";

// Utils
import { Font, rem, media } from "../../utils/style";
import { Icons } from "../../utils/svg";
import { lockScroll } from "../../utils/lockScroll";
import metrics from "../../utils/metrics";

// Components
import Swipeable from "../Swipeable";
import MagicVideo from "../MagicVideo";

// Animation
const TRANSITION_DURATION = 550;

const fadeIn = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;

// Styled Elements
const VideoOverlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 2147483647;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  transition: opacity 500ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  animation: ${fadeIn} 300ms forwards;
`;

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  width: 85vw;
  height: 62vw;
  max-width: 970px;
  max-height: 547px;

  ${media.notDesktop`
    max-width: 647px;
    max-height: 365px;
  `};

  ${media.mobile`
    width: 100vw;
    margin: 0 auto;
  `};
`;

const VideoWrapper = styled.div`
  transition: transform ${TRANSITION_DURATION}ms cubic-bezier(0.42, 0, 0.335, 1);
  z-index: ${p => p.zindex};
`;

const Video = styled(MagicVideo)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform ${TRANSITION_DURATION}ms cubic-bezier(0.42, 0, 0.335, 1);

  ${p =>
    p.nextSlide &&
    `
    &:hover {
      cursor: url("/svg/arrow-right-large.svg") 38 0, e-resize;
    }
  `};

  ${p =>
    p.previousSlide &&
    `
    &:hover {
      cursor: url("/svg/arrow-left-large.svg") 38 0, w-resize;
    }
  `};

  &:focus {
    outline: none;
  }

  video {
    width: 100% !important;
    height: 100% !important;
  }

  @media (max-height: 415px) and (orientation: landscape) {
    max-height: calc(100vh - 99px);
  }
`;

const VideoAbout = styled.div`
  position: absolute;
  left: 0;
  opacity: 0;
  bottom: -42px;
  font-size: ${rem(18)};
  transition: opacity ${TRANSITION_DURATION - 200}ms 400ms;

  ${media.mobile`
    display: flex;
    flex-direction: column;
    bottom: -82px;
    width: 100%;
    text-align: center;
  `};

  @media (max-height: 415px) and (orientation: landscape) {
    max-height: calc(100vh - 99px);
  }
`;

const Name = styled.span`
  margin-right: 8px;
  font-weight: 600;

  ${media.mobile`
    margin-bottom: 4px;
  `};
`;

const Title = styled.span`
  ${Font.dutch};
  font-size: ${rem(16)};
  font-style: italic;
`;

const Controls = styled.div`
  position: absolute;
  right: 0;
  bottom: -50px;

  ${media.mobile`
    bottom: -162px;
    width: 100%;
    text-align: center;
  `};
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0;
  top: -50px;
  display: flex;
  justify-content: flex-end;

  &:focus {
    outline: none;
  }

  ${media.mobile`
    right: 20px;
  `};
`;

const ControlButton = styled.button`
  display: inline-flex;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  width: 40px;
  height: 40px;

  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    justify-content: flex-end;
  }

  &:focus {
    outline: none;
  }

  ${media.mobile`
    width: 50px;
    height: 50px;
  `};

  svg {
    width: 18px;
    height: 12px;
    transition: transform 0.2s;
  }

  &:hover {
    svg {
      transform: scale(1.1666);
    }
  }
`;

export default function TestimonialsCarouselVideos({
  slides,
  activeSlideIndex,
  handleGoToNextSlide,
  handleGoToPreviousSlide,
  setShowVideos,
}) {
  const videoOverlayRef = useRef(null);
  const offsets = generateTransformOffsets(slides, activeSlideIndex);

  useEffect(() => {
    function logCarouselPosition(message) {
      metrics.track("Video Testimonials Engagement", {
        action: message,
        position: activeSlideIndex + 1,
        subject: slides[activeSlideIndex].name,
        nonInteraction: 0,
      });
    }

    logCarouselPosition("Playback Carousel Expanded");
    lockScroll(true, videoOverlayRef.current);
    return () => {
      logCarouselPosition("Playback Carousel Closed");
      lockScroll(false, videoOverlayRef.current);
    };
  }, []);

  return (
    <Swipeable
      onSwipedRight={handleGoToPreviousSlide}
      onSwipedLeft={handleGoToNextSlide}
    >
      <VideoOverlay ref={videoOverlayRef}>
        <VideoContainer>
          <CloseButton onClick={() => setShowVideos(false)}>
            <Icons.Close />
          </CloseButton>
          {slides.map((slide, index) => {
            const activeSlide = offsets[index] === 0;

            const transform = offsets[index];
            const opacity = transform > 200 || transform < -200 ? 0 : 1;
            const scale = activeSlide ? 1 : 0.75;

            const styles = {
              transform: `translateX(${transform}%) scale(${scale})`,
              opacity,
            };

            return (
              <CarouselVideo
                activeSlide={activeSlide}
                handleGoToNextSlide={handleGoToNextSlide}
                handleGoToPreviousSlide={handleGoToPreviousSlide}
                index={index}
                key={slide.shortname}
                slide={slide}
                styles={styles}
              />
            );
          })}
          <Controls>
            <ControlButton onClick={handleGoToPreviousSlide}>
              <Icons.ArrowRoundedLeft />
            </ControlButton>
            <ControlButton onClick={handleGoToNextSlide}>
              <Icons.ArrowRoundedRight />
            </ControlButton>
          </Controls>
        </VideoContainer>
      </VideoOverlay>
    </Swipeable>
  );
}

function CarouselVideo({
  activeSlide,
  handleGoToNextSlide,
  handleGoToPreviousSlide,
  index,
  slide,
  styles,
}) {
  const videoRef = useRef(null);
  const { image, name, title } = slide;
  const previousSlide = styles.transform.includes("(-100%)");
  const nextSlide = styles.transform.includes("(100%)");

  useEffect(() => {
    if (!activeSlide) {
      videoRef.current.pause();
    } else {
      setTimeout(() => {
        videoRef.current.play();
      }, TRANSITION_DURATION - 200);
    }
  }, [activeSlide]);

  function logVideoEvent(message, event) {
    metrics.track("Video Testimonials Engagement", {
      action: message,
      position: index + 1,
      subject: name,
      playback_time: event.target.currentTime,
      video_duration: event.target.duration,
      nonInteraction: 0,
    });
  }

  function onPlay(event) {
    logVideoEvent("Video Playback Started", event);
  }

  function onPause(event) {
    logVideoEvent("Video Playback Paused", event);
  }
  function onEnd(event) {
    logVideoEvent("Video Playback Ended", event);
    exitFullscreen();
    setTimeout(() => handleGoToNextSlide(), 750);
  }

  function handleNextAndPreviousClick() {
    if (previousSlide) {
      handleGoToPreviousSlide();
    }
    if (nextSlide) {
      handleGoToNextSlide();
    }

    return false;
  }

  const { videoContent } = slide;

  return (
    <VideoWrapper zindex={activeSlide ? 9999 : 0}>
      <Video
        autoPlay={activeSlide ? "autoplay" : false}
        controls={activeSlide}
        crossorigin="anonymous"
        preload="none"
        poster={image.file.url}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={onEnd}
        onClick={handleNextAndPreviousClick}
        nextSlide={nextSlide}
        previousSlide={previousSlide}
        videoStyle={styles}
        sources={videoContent.sources}
        captions={videoContent.captions}
        ref={videoRef}
      />
      <VideoAbout style={activeSlide ? { opacity: 1 } : { transition: "none" }}>
        <Name>{name}</Name> <Title>{title}</Title>
      </VideoAbout>
    </VideoWrapper>
  );
}

function generateTransformOffsets(slides, activeSlideIndex) {
  const offsets = slides.map((_, index) => {
    if (slides.length / 2 < index) {
      return (index - slides.length) * 100 * -1;
    } else {
      return index * 100 * -1;
    }
  });

  let newOffsets = [];

  for (let i = 0; i < offsets.length; i++) {
    let pointer =
      (Math.abs(i - offsets.length) + activeSlideIndex) % offsets.length;
    newOffsets.push(offsets[pointer]);
  }

  return newOffsets;
}

function exitFullscreen() {
  const {
    fullScreenElement,
    webkitFullscreenElement,
    mozFullScreenElement,
  } = document;

  const isFullScreenElement =
    fullScreenElement || webkitFullscreenElement || mozFullScreenElement;

  if (!isFullScreenElement) return;

  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
}
