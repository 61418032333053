import React from "react";
import { Icons, ClinicalIcons } from "../../utils/svg";
import {
  Font,
  Color,
  rem,
  media,
  capitalize,
  responsive,
} from "../../utils/style";
import styled, { css } from "styled-components";
import Container from "../Container";
import Row from "../Row";

const Wrapper = styled(Container).attrs({
  id: "value-props",
})`
  ${p =>
    p.margin20 &&
    css`
      ${media.mobile`
        padding: 0 20px;
      `}
    `}
`;

const StyledRow = styled(Row).attrs({
  id: "value-props_row",
})`
  position: relative;
  z-index: 1;

  ${media.mobile`
    border: none;
  `};
`;

const Column = styled.div`
  padding-top: ${p => (p.removePadding ? "0px" : "40px")};
  padding-bottom: ${p => (p.removePadding ? "0px" : "40px")};
  position: relative;

  ${media.mobile`

    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 20px;
      right: 20px;
    }
  `};
`;

const PropsArea = styled.div.attrs({
  id: "value-props_row_column_area",
})`
  display: flex;
  justify-content: space-between;

  ${media.mobile`
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  `};
`;

const ValueProp = styled.div.attrs(p => ({
  id: `value-props_row_column_area_prop-${p.index}`,
}))`
  display: flex;
  flex-direction: column;
  width: 134px;

  &:last-child {
    margin-right: 0;
  }

  ${media.tablet`
    margin-right: 8px;
  `};

  ${media.mobile`
    flex-direction: row;
    width: 100%;
    margin: 0 0 24px 0;


    &:last-child {
      margin: 0;
    }
  `};
`;

const Icon = styled.div.attrs(p => ({
  id: `value-props_row_column_area_prop-${p.index}_icon`,
}))`
  position: relative;
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  margin: 0 8px 0 0;

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  ${responsive.sm`
    width: 40px;
    height: 40px;
    margin: 0 auto 16px;
  `}

  ${responsive.md`
    min-height: 40px;
  `}
`;

const ValuePropLabel = styled.span.attrs(p => ({
  id: `value-props_row_column_area_prop-${p.index}_label`,
}))`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(16)};
  line-height: ${rem(22)};
  font-weight: 300;
  text-align: center;
  flex: 1 1 100%;

  ${media.mobile`
    text-align: left;
    flex: 1 1 auto;
    align-self: center;
  `}
`;

export default class ValueProps extends React.Component {
  renderValueProp(valueProp, icon, i) {
    let cleanedIconName = icon.replace("icon", "").replace(/-/gi, "");
    let IconElement =
      Icons[capitalize(cleanedIconName)] ||
      ClinicalIcons[capitalize(cleanedIconName)];
    return (
      <ValueProp index={i} key={"value-prop" + i}>
        <Icon index={i}>
          <IconElement />
        </Icon>
        <ValuePropLabel index={i}>{valueProp}</ValuePropLabel>
      </ValueProp>
    );
  }

  render() {
    let {
      valueProps,
      icons,
      location,
      removePadding,
      className = "col-lg-10 offset-lg-1",
      margin20,
    } = this.props;

    if (typeof valueProps !== "object" || typeof icons !== "object")
      return null;

    return (
      <Wrapper margin20={!!margin20}>
        <StyledRow>
          <Column className={className} removePadding={removePadding}>
            <PropsArea>
              {valueProps.map((valueProp, i) => {
                return this.renderValueProp(valueProp, icons[i], i);
              })}
            </PropsArea>
          </Column>
        </StyledRow>
      </Wrapper>
    );
  }
}
