import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Styled Elements
const MagicVideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    max-width: 100%;
    max-height: 100%;

    [data-whatintent="mouse"] &:focus,
    [data-whatintent="touch"] &:focus {
      outline: none;
    }
  }

  ${p => p.videoStyle};
`;

// Video related
const langLabel = {
  en: "English",
};

export default class MagicVideo extends React.Component {
  static propTypes = {
    captions: PropTypes.array,
    className: PropTypes.string,
    sources: PropTypes.array,
    videoStyle: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.videoElement = React.createRef();
    this.locale = null;
  }

  componentDidMount() {
    this.locale = window.navigator.language;
  }

  play() {
    this.videoElement.current.play();
  }

  pause() {
    this.videoElement.current.pause();
  }

  render() {
    const {
      /* Attributes */
      autoPlay = false,
      captions,
      controls = true,
      crossorigin,
      loop = false,
      poster,
      preload = "metadata",
      sources,
      /* Interaction Callbacks */
      onPause,
      onEnded,
      onPlay,
      onSeeking,
      onSeeked,
      onClick,
      /* Other */
      className,
      videoStyle = {},
      videoElementStyle = {},
    } = this.props;

    if (!sources) {
      console.warn("No video sources found");
      return null;
    }

    if (!captions) console.warn("Captions missing for video");

    let videoProps = {
      /* Attributes */
      autoPlay,
      controls,
      loop,
      poster,
      preload,
      /* Interaction Callbacks */
      onPause,
      onEnded,
      onPlay,
      onSeeking,
      onSeeked,
      onClick,
    };

    if (crossorigin) {
      videoProps.crossOrigin = crossorigin;
    }

    /* eslint-disable jsx-a11y/media-has-caption */
    /* ^ jsx-a11y cant see <track> inside the .map ¯\_(ツ)_/¯ */
    return (
      <MagicVideoWrapper className={className} videoStyle={videoStyle}>
        <video
          /* Props */
          {...videoProps}
          /* Other */
          ref={this.videoElement}
          style={videoElementStyle}
        >
          {sources.map((source, i) => {
            const { file, id } = source;
            return (
              <source id={id} key={i} src={file.url} type={file.contentType} />
            );
          })}
          {captions &&
            captions.map((caption, i) => {
              let { description, file } = caption;
              if (!description) description = "en-us";

              return (
                <track
                  key={i}
                  kind="captions"
                  src={file.url}
                  srcLang={description}
                  label={langLabel[description]}
                  default={description === this.locale}
                />
              );
            })}
        </video>
      </MagicVideoWrapper>
    );
    /* eslint-enable */
  }
}
